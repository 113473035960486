/* Code Quality: Good */

import React, { forwardRef, useCallback, useEffect } from 'react'

import styled from '@emotion/styled'
import isEqual from 'lodash/isEqual'
import { ViewLayoutContext } from 'v2/blocks/types'

import { getStackTheme } from 'app/Branding'
import roleUpdate from 'data/utils/roleUpdate'
import { withFeatures } from 'data/wrappers/WithFeatures'
import { withNavigation } from 'data/wrappers/WithNavigation'
import { withPages } from 'data/wrappers/WithPages'
import { withStack, withStacks } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { withViews } from 'data/wrappers/WithViews'
import OnboardingRedirector from 'features/admin/onboarding/OnboardingRedirector'
import { navigationColor } from 'features/admin/settings/navigation/NavigationUtils'
import ThemeWrapper from 'features/core/themes/ThemeWrapper'
import { getLogoUrl } from 'features/utils/getLogo'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { wsNavThemeToV3 } from 'features/workspace/WorkspaceSettingsModalUi'
import { updateIntercom, withCanAccessSupport } from 'utils/intercom'

import usePrevious from 'v2/ui/hooks/usePrevious'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useRefCallback from 'v2/ui/utils/useRefCallback'

import { getNavigationColor } from '../../../v2/ui/hooks/useNavigationColor'

import DesktopHeaderBar from './DesktopHeaderBar'
import MobileHeaderBar from './MobileHeaderBar'
import { HEADER_HEIGHT } from './navUtils'
import { SecondNavigation } from './SecondaryNavigation'

type HeaderProps = {
    stack: StackDto
    isImpersonating: boolean
    isStudioUser: boolean
    studioUser: UserDto
    workspaceAccount: Account
    canAccessSupport: boolean
    stackOptions: StackDto['options']
    context: ViewLayoutContext
    stacks: StackDto[]
    hideNavItems: boolean
    onMobileMenuClick: () => void
    hideHeader: boolean
    views: ViewDto[]
    pages: PageDto[]
    features: FeatureDto[]
    navigation: NavigationDto[]
    children?: React.ReactNode
    onSecondaryHeaderStateChange?: (isVisible: boolean) => void
}

const Header: React.FC<HeaderProps> = ({
    stack,
    isImpersonating,
    isStudioUser,
    studioUser,
    workspaceAccount,
    stackOptions,
    context,
    stacks,
    hideNavItems,
    onMobileMenuClick,
    hideHeader,
    views,
    pages,
    features,
    navigation,
    onSecondaryHeaderStateChange,
}) => {
    const [outerHeaderRef, setOuterHeaderRef] = useRefCallback()

    // makes sure that intercom has the current subscription data
    const updatePlan = useCallback(() => {
        if (!stack || !stack.subscription) return
        const { plan } = stack.subscription
        if (isStudioUser && !isImpersonating && plan) {
            const userInfo = {
                'Has Subscribed': plan !== 'FREE',
                'Current Plan': plan,
            }

            updateIntercom(userInfo)
        }
    }, [isImpersonating, isStudioUser, stack])

    const previousSubscription = usePrevious(stack?.subscription)

    useEffect(() => {
        if (!isEqual(previousSubscription, stack?.subscription)) {
            updatePlan()
        }

        roleUpdate(previousSubscription, stack)
    }, [previousSubscription, stack, stack?.subscription, updatePlan])

    const shouldHideAppBarForGuest = () => {
        const filteredStacks = stacks?.filter(
            (stack) => stack.account_id === workspaceAccount?._sid
        )
        const isGuest = studioUser?.membership_options?.role === 'guest'
        return isGuest && filteredStacks?.length === 1
    }

    if (hideHeader) return null
    if (!stack._sid) return null
    const options = stackOptions || {}
    let logoUrl = getLogoUrl(stack)
    const theme = getStackTheme(stack)

    // show the 'default stack logo' if there's no logo set in the theme, and it's enabled for the stack
    // NOTE: if it's not enabled for the stack, and there is no logo set in the theme, it defaults to the Stacker logo image
    let navTheme: { [key: string]: any } = navigationColor(
        theme,
        false,
        stack.options.workspace_app
    )
    const stackLogo = getLogoUrl(
        stack,
        false,
        !theme.enableDefaultStackLogo ? navTheme.stackerLogo : null
    )
    const showDefaultStackLogo = theme && !stackLogo && theme.enableDefaultStackLogo

    if (workspaceAccount && !isImpersonating) {
        navTheme = wsNavThemeToV3(getNavigationColor(theme))
    }
    navTheme.height = HEADER_HEIGHT + 'px'

    return (
        <ThemeWrapper>
            {isStudioUser && !isImpersonating && <OnboardingRedirector />}
            <NavigationBar ref={setOuterHeaderRef} className={STYLE_CLASSES.HEADER_OUTER}>
                {window.innerWidth > 768 ? (
                    <>
                        <DesktopHeaderBar
                            context={context}
                            studioUser={studioUser}
                            theme={theme}
                            navTheme={navTheme}
                            options={options}
                            workspaceAccount={workspaceAccount}
                            isStudioUser={isStudioUser}
                            showDefaultStackLogo={showDefaultStackLogo}
                            stack={stack}
                            logoUrl={logoUrl}
                            stacks={stacks}
                            shouldHideAppBarForGuest={shouldHideAppBarForGuest()}
                            outerHeaderRef={outerHeaderRef}
                            views={views}
                            pages={pages}
                            navigation={navigation}
                            features={features}
                        />
                        <SecondNavigation
                            views={views}
                            theme={theme}
                            pages={pages}
                            navigation={navigation}
                            features={features}
                            isWorkspace={stack.options.workspace_app}
                            onSecondaryHeaderStateChange={onSecondaryHeaderStateChange}
                        />
                    </>
                ) : (
                    <MobileHeaderBar
                        theme={theme}
                        navTheme={navTheme}
                        logoUrl={logoUrl}
                        hideNavItems={hideNavItems}
                        onMobileMenuClick={onMobileMenuClick}
                        stack={stack}
                        workspaceAccount={workspaceAccount}
                        studioUser={studioUser}
                        showDefaultStackLogo={showDefaultStackLogo}
                        shouldHideAppBarForGuest={shouldHideAppBarForGuest()}
                        navigation={navigation}
                        pages={pages}
                        features={features}
                        views={views}
                        isImpersonating={isImpersonating}
                    ></MobileHeaderBar>
                )}
            </NavigationBar>
        </ThemeWrapper>
    )
}

export default withCanAccessSupport(
    withFeatures(withPages(withViews(withNavigation(withUser(withStacks(withStack(Header)))))))
)

const NavigationBarStyled = styled.div`
    width: 100%;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
`
type NavigationBarProps = {
    children?: React.ReactNode
    className?: string
}

const NavigationBar = forwardRef<HTMLDivElement, NavigationBarProps>((props, ref) => {
    const { handleClickOutside: handleClickOutsideSidePane } = useSlidingPane()

    return <NavigationBarStyled ref={ref} {...props} onClick={handleClickOutsideSidePane} />
})

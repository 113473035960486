import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Spinner } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import { getUrl, Urls } from 'app/UrlService'
import { UserApi } from 'data/api/userApi'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { useInvitation } from 'data/hooks/useInvitation'
import { withUser } from 'data/wrappers/WithUser'
import analytics from 'utils/analytics'
import useStableState from 'utils/useStableState'

import { Avatar, Box, Collapse, Flex, Icon } from 'v2/ui'

import useAuth0Redirect from '../useAuth0Redirect'

import { Button, Text } from './AuthUIElements'
import EditProfile from './EditProfile'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import { GoogleLoginButton } from './SignInFlow'

const AcceptInviteFlow = ({ user, userActions }) => {
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    // we useStableState here because upon authentication, the entire component tree
    // gets recreated from scratch and any local state will get reset. We want to preserve
    // the user that we have successfully authed so that we can differentiate between
    // a fresh page load that has an authed user, and the recreated state which will also have
    // an auth user, which is the one we just finished authing.
    const [newlyAuthedUser, setNewlyAuthedUser] = useStableState('InviteFlow.User')
    const [redirecting, setRedirecting] = useState()
    const history = useHistory()
    const [error, setError] = useState()
    const { isFetching: loadingMetadata } = useInitialMetadata()
    const { selectedStack } = useAppContext()
    const auth0Redirect = useAuth0Redirect()
    const logoutAllUsers = userActions.logOutAllUsers
    const [openSignupError, setOpenSignupError] = useState()

    const [emailOptIn, setEmailOptIn] = useState(true)

    // For_stack is a unique identifier for the open signup flow
    // Reserve for NUM open signup only
    const forStack = query.for_stack

    const { isLoading: invitationLoading, data: invitation } = useInvitation(query.invite, forStack)

    const stackSlug = invitation?.open_signup_stack?.stack_slug
    const openSignup = forStack && stackSlug

    useEffect(() => {
        // If we have these items in the URL, it's an Auth0 invitation so redirect to the Auth0 flow
        if (query.ticket && query.organization && !redirecting) {
            setRedirecting(true)
            logoutAllUsers()
            auth0Redirect({ invitation: query.ticket, organization: query.organization })
        }
        // if the user is already an existing auth0 user, redirect to the auth0 login
        else if (invitation?.user.is_auth0 && invitation?.workspace.is_auth0 && !redirecting) {
            setRedirecting(true)
            logoutAllUsers()
            auth0Redirect({ prompt: 'login', login_hint: invitation?.user.email })
        }
    }, [invitation, query.ticket, query.organization, auth0Redirect, logoutAllUsers, redirecting])

    const doRedirect = useCallback(() => {
        setRedirecting(true)
        // If the final authenticated user matches the invited user, redirect to workspace
        if (user._sid === invitation?.user._sid) {
            analytics.track('app user invite accepted', {
                workspace_id: user.account_id,
                user_id: user._sid,
                app_id: selectedStack?._sid,
                event_category: 'app',
                event_description: 'New app user was invited',
                referred_user_email: invitation?.sender?.email,
            })
            {
                openSignup
                    ? window.location.assign(`//${invitation.workspace.domain}/${stackSlug}`)
                    : window.location.assign(
                          `//${invitation.workspace.domain}/auth?token=${user.api_token}`
                      )
            }
        } else {
            // otherwise, just send to the stacks page:
            history.push(getUrl(Urls.Stacks))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, invitation, setRedirecting, history])

    // check to see if we're in a state where we're ready to redirect the user
    useEffect(() => {
        // If we have an authenticated user, and it's the right user for the invitation
        // then proceed to the next step, and we don't have a newly authed user...
        // it means the user is already logged into the right account, so just redirect
        if (
            user &&
            invitation &&
            user._sid === invitation.user._sid &&
            !newlyAuthedUser &&
            !openSignup
        ) {
            doRedirect()

            // If we have a newly authed user, and it isn't a new registration, go ahead and redirect
        } else if (user && newlyAuthedUser && invitation && !newlyAuthedUser.is_new_user) {
            doRedirect()
            // If we have a newly authed user, but it's not the user for the invitation
            // we can also go ahead and redirect.
            // This can happen if the user clicks on a previously accepted link, but does google
            // auth with a completely different account. The newly authed user won't match the invitation
            // so we just send them to the Stacks page.
        } else if (user && newlyAuthedUser && newlyAuthedUser._sid !== invitation?.user._sid) {
            doRedirect()
        }
    }, [user, invitation, newlyAuthedUser, doRedirect, openSignup])

    useEffect(() => {
        // Make sure users with already logged in sessions get added to the open signup stack after
        // Clicking on invitation link in email
        if (
            openSignup &&
            user &&
            invitation &&
            user._sid === invitation.user._sid &&
            !newlyAuthedUser
        ) {
            UserApi.handleOpenSignUp(invitation.user.email, forStack, invitation.token)
                .then((response) => {
                    if (
                        !(
                            response.success &&
                            (response.status == 'registration_confirmed' ||
                                'already_registered_skip_email')
                        )
                    ) {
                        setOpenSignupError(true)
                        return
                    }
                    analytics.track('open signup registration completed', {
                        workspace_id: invitation?.workspace?._sid,
                        user_id: invitation?.user?._sid,
                        event_description: 'Open signup registration was completed',
                        is_new_user: invitation?.user?.is_new_user,
                        authentication_method: 'email',
                    })
                    doRedirect()
                })
                .catch((ex) => {
                    Sentry.captureException(ex)
                    setOpenSignupError(true)
                    return
                })
        }
    }, [user, invitation, newlyAuthedUser, doRedirect, forStack, openSignup, stackSlug])

    const logErrorMessage = (message, error) => {
        Sentry.withScope((scope) => {
            if (user) {
                const { name, email } = user
                scope.setExtra('email', email)
                scope.setExtra('name', name)
            }
            const query = queryString.parse(window.location.search, {
                ignoreQueryPrefix: true,
            })

            const options = { signup_params: query }

            scope.setExtra('options', options)
            if (error) {
                scope.setExtra('message', message)

                Sentry.captureException(error)
            } else {
                Sentry.captureMessage(message)
            }
        })
    }

    const handleProfileComplete = () => {
        doRedirect()
    }

    const handleSuccess = (user) => {
        setError(null)
        if (!user.is_existing_user) {
            const { email, name, _sid, account_id } = user

            try {
                analytics.identify(_sid, {
                    email,
                    name,
                })
                analytics.plugins?.segment?.group(account_id, {
                    group_type: 'account id',
                    group_value: account_id,
                })

                // linkedin tracking of account created event
                if (window.lintrk) {
                    window.lintrk('track', { conversion_id: 4612562 })
                }
            } catch (e) {
                logErrorMessage('Exception when sending account created event on signup', e)
            }
        }
        setNewlyAuthedUser({ _sid: user._sid, is_new_user: invitation.user.is_new_user })
        userActions.studioUserLoggedIn(user)
    }

    const handleFailure = (response) => {
        Sentry.withScope((scope) => {
            scope.setExtra('response', response)
            scope.setLevel('error')
            Sentry.captureMessage('Unable to accept invitation')
        })

        setError(response?.detail)
    }

    const handleLogout = (e) => {
        userActions.logOutAllUsers()
        e.preventDefault()
    }

    const isLoading = loadingMetadata || redirecting || invitationLoading

    // Show login instead of signup if this invitation is for an existing user
    const showLogin = invitation && !isLoading && !user && !invitation?.user?.is_new_user
    const showSignUp = invitation && !isLoading && !user && invitation?.user?.is_new_user

    return (
        <>
            <Collapse isOpen={!!isLoading}>
                <Flex height="100%" align="center" column py="100px">
                    <Spinner />
                </Flex>
            </Collapse>
            <Collapse isOpen={!!(!isLoading && invitation && !newlyAuthedUser)}>
                <DisplayInvitation invitation={invitation} />
            </Collapse>
            <Collapse isOpen={!!(!isLoading && !invitation)}>
                <Text my={6} bg="gray.100" p={4} rounded="md">
                    <Icon icon="alert" mr={2} display="inline" /> An error occurred retrieving your
                    invitation. Please check the URL and try again.
                </Text>
            </Collapse>
            <Collapse isOpen={!!(!isLoading && error)}>
                <Text my={6} variant="error">
                    {error}
                </Text>
            </Collapse>
            <Collapse isOpen={!!(!isLoading && openSignupError)}>
                <Text my={6} variant="error">
                    Sorry, an error occurred trying to register.
                </Text>
            </Collapse>
            <Collapse
                isOpen={
                    !!(!isLoading && user && invitation && user?._sid !== invitation?.user._sid)
                }
            >
                <LoggedInWrongAccount user={user} invitation={invitation} onLogout={handleLogout} />
            </Collapse>
            <Collapse isOpen={!!showSignUp}>
                {showSignUp && (
                    <Flex align="stretch" column>
                        {openSignup ? (
                            <RegisterForm
                                onSuccess={handleSuccess}
                                onRejected={handleFailure}
                                invitation={invitation}
                                forStack={forStack}
                                emailOptIn={emailOptIn}
                                setEmailOptIn={setEmailOptIn}
                                openSignup
                            />
                        ) : (
                            <>
                                <GoogleLoginButton
                                    onSuccess={handleSuccess}
                                    text="Sign up with Google"
                                    onRejected={handleFailure}
                                    invitation={invitation}
                                    isSignUp
                                />
                                <Text my={6} alignSelf="center">
                                    - or -
                                </Text>
                                <RegisterForm
                                    onSuccess={handleSuccess}
                                    onRejected={handleFailure}
                                    invitation={invitation}
                                    emailOptIn={emailOptIn}
                                    setEmailOptIn={setEmailOptIn}
                                />
                            </>
                        )}
                    </Flex>
                )}
            </Collapse>

            <Collapse isOpen={!!showLogin}>
                {showLogin && (
                    <Flex align="stretch" column>
                        {openSignup ? (
                            <>
                                {invitation?.user?.has_password ? (
                                    <LoginForm
                                        onSuccess={handleSuccess}
                                        onRejected={handleFailure}
                                        onLogIn={userActions.logInStudioUser}
                                        showRegisterLink={false}
                                        invitation={invitation}
                                        forStack={forStack}
                                        openSignup
                                    />
                                ) : (
                                    <RegisterForm
                                        onSuccess={handleSuccess}
                                        onRejected={handleFailure}
                                        email={invitation?.user?.email}
                                        invitation={invitation}
                                        emailOptIn={emailOptIn}
                                        setEmailOptIn={setEmailOptIn}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <GoogleLoginButton
                                    onSuccess={handleSuccess}
                                    onRejected={handleFailure}
                                    invitation={invitation}
                                />

                                {invitation?.user?.has_password && (
                                    <>
                                        <Text my={6} alignSelf="center">
                                            - or -
                                        </Text>

                                        <LoginForm
                                            onSuccess={handleSuccess}
                                            onRejected={handleFailure}
                                            onLogIn={userActions.logInStudioUser}
                                            showRegisterLink={false}
                                            invitation={invitation}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Flex>
                )}
            </Collapse>
            <Collapse
                isOpen={!!(!isLoading && user && newlyAuthedUser && newlyAuthedUser.is_new_user)}
            >
                <>
                    <Text variant="onboardingInstructionHeading">Create your profile</Text>
                    <EditProfile user={user} onSuccess={handleProfileComplete} />
                </>
            </Collapse>
        </>
    )
}

const DisplayInvitation = ({ invitation }) => {
    if (!invitation) return null

    // If the sender and the user are the same (as in open sign-up) show slightly different logic
    const showSender = !(invitation?.sender?.email === invitation?.user?.email)

    return (
        <>
            {showSender ? (
                <Flex align="center" column mb={8}>
                    <Avatar src={invitation.sender.avatar} size="xl" />
                    <Box mt={4}>
                        <Text display="inline" fontWeight="bold">
                            {invitation.sender.name}
                        </Text>
                        <Text display="inline"> invited you to </Text>
                        <Text display="inline" fontWeight="bold">
                            {invitation.workspace.name}
                        </Text>
                    </Box>
                </Flex>
            ) : (
                <Collapse isOpen={true}>
                    <Flex align="center" column mb={8}>
                        <Box mt={4}>
                            <Text variant="authenticationText" display="inline">
                                Complete your registration for{' '}
                            </Text>
                            <Text display="inline" fontWeight="bold">
                                {invitation?.workspace?.name}
                            </Text>
                        </Box>
                    </Flex>
                </Collapse>
            )}
        </>
    )
}

const LoggedInWrongAccount = ({ user, invitation, onLogout }) => {
    return (
        <Flex
            column
            align="center"
            borderSize={1}
            borderColor="#22222233"
            borderStyle="solid"
            rounded="md"
            p={8}
        >
            <Icon icon="info" size="xxxl" color="grey.200" />
            <Text variant="authenticationText" my={6}>
                You are already logged in as <strong>{user?.email}</strong>.
            </Text>
            <Text variant="authenticationText" mb={6} fontSize="sm">
                You must log in as <strong>{invitation?.user.email}</strong> to proceed.
            </Text>
            <Button variant="adminPrimary" onClick={onLogout} buttonSize="sm">
                Continue
            </Button>
        </Flex>
    )
}

export default withUser(AcceptInviteFlow)

import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import Color from 'color'
import PropTypes from 'prop-types'

import { ErrorBoundary } from 'app/ErrorBoundaries'
import { withStack } from 'data/wrappers/WithStacks'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import Container from 'legacy/v1/ui/components/Container'
import { Heading, Input, Section } from 'legacy/v1/ui/index'
import publicAsset from 'utils/publicAsset'

import { Box, Button, Collapse, Divider, Flex, Text } from 'v2/ui'

const SignupFrame = ({
    children,
    rightContents,
    title,
    onNextClick,
    nextDisabled,
    nextText,
    nextHref,
    nextVariant,
    showSkip,
    onSkipClick,
    skipText,
    isNextLoading,
    hideLogo,
    mainSectionStyle,
    noPadding,
}) => {
    const mobile = window.innerWidth <= 768
    return (
        <Background>
            <ErrorBoundary>
                <Box
                    boxShadow={[null, null, 'sm', 'sm']}
                    bg="white"
                    width="100%"
                    maxWidth="1000px"
                    height={mobile ? '100%' : '640px'}
                >
                    <Container noMargin noPadding style={{ height: '100%' }}>
                        <MainSection
                            title={title}
                            width={rightContents && !mobile ? '50%' : '100%'}
                            onNextClick={onNextClick}
                            nextDisabled={nextDisabled}
                            isNextLoading={isNextLoading}
                            hideLogo={hideLogo}
                            nextText={nextText}
                            nextHref={nextHref}
                            nextVariant={nextVariant}
                            style={mainSectionStyle}
                            showSkip={showSkip}
                            onSkipClick={onSkipClick}
                            skipText={skipText}
                        >
                            {children}
                        </MainSection>
                        {rightContents && !mobile && (
                            <RightSection noPadding={noPadding}>{rightContents}</RightSection>
                        )}
                    </Container>
                </Box>
            </ErrorBoundary>
            <DefaultHeaderMeta title="Stacker" />
        </Background>
    )
}

const MainSection = ({
    title,
    children,
    onNextClick,
    nextHref,
    nextDisabled,
    nextText,
    hideLogo,
    nextVariant,
    isNextLoading,
    showSkip,
    onSkipClick,
    skipText,
    style,
    ...props
}) => {
    const mobile = window.innerWidth <= 768

    return (
        <Flex
            px={[4, 4, 8, 8]}
            pt={[4, 4, 8, 8]}
            pb={['28px', null, 8, 8]}
            {...props}
            column
            wrap="noWrap"
            height="100%"
            align="stretch"
            position="relative"
        >
            <Collapse isOpen={!!!hideLogo}>
                <Flex column>
                    <Box alignSelf={mobile ? 'center' : 'flex-start'}>
                        <Logo />
                    </Box>
                    <Divider mt={[3, null, 4, null]} flexShrink={0} alignSelf="stretch" />
                </Flex>
            </Collapse>
            <Flex
                flexGrow={1}
                overflowY="auto"
                alignSelf="stretch"
                column
                align="stretch"
                justifyContent={mobile || !title ? 'center' : 'flex-start'}
                style={style}
            >
                <Box px={[4, 4, 4, 4]} py={[8, 8, 12, 12]} maxHeight="100%" maxWidth="100%">
                    <Collapse isOpen={!!title}>
                        <Text variant="onboardingTitle">{title}</Text>
                    </Collapse>
                    {children}
                </Box>
            </Flex>
            <Box alignSelf={mobile ? 'center' : 'flex-end'} bg="white">
                <Collapse isOpen={!!(onNextClick || nextHref)}>
                    {showSkip && (
                        <Button
                            mt={3}
                            mr={4}
                            buttonSize="md"
                            variant="adminSecondary"
                            onClick={onSkipClick}
                            href={nextHref}
                        >
                            {skipText || 'Skip'}
                        </Button>
                    )}
                    <Button
                        mt={3}
                        buttonSize="md"
                        variant={nextVariant || 'onboardingPrimary'}
                        onClick={onNextClick}
                        disabled={nextDisabled}
                        isLoading={isNextLoading}
                        href={nextHref}
                    >
                        {nextText || 'Next step'}
                    </Button>
                </Collapse>
            </Box>

            {/* Not using this any more, but leaving the code here in case it's handy for the next time
            flow where we are needing to test it on staging for a while before prod.
            {!settings.IS_PROD && <DevModeWorkspaceRegistrationSwitcher />} */}
        </Flex>
    )
}

SignupFrame.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

const LogoContainer = styled.img`
    width: 100px;
`
export const Logo = ({ src }) => (
    <LogoContainer
        src={src || publicAsset('/static/media/stacker-logo-colour.png')}
        alt="Stacker Logo"
        data-testid="stacker-logo"
    />
)

const Background = styled.div`
    height: 100%;
    width: 100vw;
    background: ${(props) => props.theme.backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const MobileNoticeText = styled(Text)`
    margin-top: 48px;
`
export default SignupFrame

export const LeftSection = styled(Section)`
    width: 50%;
    height: 100%;
    margin: 0px;
    padding: 32px;
    position: relative;
`

export const SignupFormSection = styled(Section)`
    padding: 16px;
    margin-top: auto;
    margin-bottom: auto;
    @media only screen and (min-width: 768px) {
        padding: 32px;
    }
`

export const MobileSection = styled(Section)`
    height: 100%;
    margin: 0px;
    padding: 16px;
    position: relative;
`

export const lighten = (hex) => Color(hex).lighten(0.8).hex()
export const darken = (hex) => Color(hex).darken(0.4).saturate(0.5).hex()

export const RightSection = styled(Section)`
    background-color: ${(props) => (props.color ? lighten(props.color) : '#edecfd')};
    width: 50%;
    height: 100%;
    margin: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.justifyEnd ? 'flex-end' : 'center')};
    ${(props) => (props.noPadding ? 'padding:0px;' : '')}
`

export const Title = styled(Heading)`
    font-weight: 700;
    font-size: 36px;
    font-color: #253242;
    margin-top: 20px;
`

export const InputLabel = styled(Text)`
    opacity: 0.6;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
`

export const LargerInputLabel = styled(Text)`
    opacity: 0.6;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
`

export const OnboardingInput = styled(Input)`
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    & :-webkit-autofill {
        // Hide autofil colours
        background-color: white !important;
        -webkit-box-shadow: 0 0 0 50px white inset;
        border-bottom: 1px solid #eee;
        font-size: inherit;
    }
`

export const OnboardingButton = styled(Button)`
    background-color: #705dee;
    border: none;
    color: white;
    & :active,
    & :hover {
        background-color: #352c73;
        color: white;
    }
`

export const NextButtonContainer = styled('div')`
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin: 20px;
    flex-direction: row;
`

export const _NextButton = ({ stackOptions, style, link, ...props }) => {
    const color = stackOptions.theme && stackOptions.theme.brandColor
    const button = (
        <OnboardingButton style={{ width: 120, margin: 0, ...style }} color={color} {...props} />
    )
    if (link) {
        return <Link to={link}>{button} </Link>
    } else {
        return button
    }
}

export const NextButton = withStack(_NextButton)

/* Code Quality: Bad */

import React from 'react'
import { withRouter } from 'react-router-dom'

import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import queryString from 'qs'

import HELP_LINKS from 'app/helpLinks'
import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { getCollection } from 'data/githubFeatures'
import { fetchWithAuth } from 'data/utils/utils'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { HelpLink, Section, Text } from 'legacy/v1/ui'

import { Collapse, Flex, Input } from 'v2/ui'

import analytics from '../../../utils/analytics'
import { isStudioDomain, setFeatureLogo } from '../utils'

import AppPreview from './ui/AppPreview'
import SignupFrame, { LargerInputLabel } from './ui/SignupFrame'

const WAIT_INTERVAL = 200

class SignupSetNamePage extends React.Component {
    state = {
        portalName: '',
        isValid: false,
        title: 'Stacker',
        logo: '',
        icon: '',
        placeholder: '',
        isRedirecting: false,
    }

    componentDidMount() {
        const deviceType = window.innerWidth > 768 ? 'desktop' : 'mobile'
        const query = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        // If we're on mobile, and this isn't a gsheets portal,
        // then redirect to our mobile catchall page. We don't allow
        // onboarding AT on mobile
        if (deviceType === 'mobile' && query.type !== 'gsheets') {
            this.setState({ isRedirecting: true })
            window.location.href = `https://stacker.app/post-signup`
        }
    }
    UNSAFE_componentWillMount() {
        this.timer = null
        const { user } = this.props
        if (!user) {
            window.location = getUrl(Urls.Register)
        }

        const query = queryString.parse(this.props.location.search.slice(1))
        this.setState({ query })
        const collection = getCollection()

        setFeatureLogo(this, collection)
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.register()
        }
    }

    checkPortalAvailableNames = (portal) => {
        if (portal === '') {
            this.setState({ portalName: '' })
            this.setState({ isValid: false })
            return
        }
        const portalName = portal

        const url = `stack/name/?name=${encodeURIComponent(portalName)}&domain=${encodeURIComponent(
            settings.USER_DOMAIN
        )}`
        fetchWithAuth(url)
            .then((response) => {
                if (response.status >= 400) {
                    console.error('Unable to fetch valid app names ', response)
                    this.setState({ isValid: false })
                } else
                    response.json().then((json) => {
                        const baseUrl = json.base_url // This should look like subdomain.stacker.app
                        const subdomain = baseUrl.split('.')[0] // SPlit by . to get the first subdomain
                        if (!subdomain) {
                            // subdomain is not valid (maybe because we only entered invalid characters)
                            this.setState({ portalName: '' })
                            this.setState({ isValid: false })
                        } else {
                            this.setState({ portalName: baseUrl })
                            this.setState({ isValid: true })
                        }
                    })
            })
            .catch((error) => {
                console.error('Error when fetching valid app names ', error)
                this.setState({ isValid: false })
            })
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.register()
        }
    }

    register = () => {
        if (!this.state.portal) {
            this.setState({ error: true })
            return
        }

        // Ignore subsequent clicks or key presses
        // that trigger this
        if (this.registrationStarted) return

        this.registrationStarted = true

        analytics.track('onboarding progressed', {
            step: 'set_name_page',
        })

        // Remove previously stored stacks,
        // just in case you really want to create a new stack with the same name
        localStorage.removeItem('stack_name')
        localStorage.removeItem('stack_url')

        let query = window.location.search
        const encodedPortalName = encodeURIComponent(this.state.portal)
        query += `${query.startsWith('?') ? '&' : '?'}portal=${encodedPortalName}`

        var nextStep = isStudioDomain() ? Urls.Register4 : Urls.AdminNewAppCreate

        this.props.history.push(getUrl(`${nextStep}${query}`))
    }

    render() {
        // Slice to remove the "?"
        return (
            <SignupFrame
                noPadding
                title={!this.state.isRedirecting && 'Set up your app'}
                rightContents={<AppPreview name={this.state.portal} />}
                onNextClick={this.register}
                nextDisabled={!this.state.isValid || this.state.processing}
            >
                {this.state.isRedirecting ? (
                    <Flex column>
                        <Spinner />
                    </Flex>
                ) : (
                    <>
                        <Section margin="medium" padding="none">
                            <LargerInputLabel>Choose a project name</LargerInputLabel>

                            <Input
                                onKeyDown={this.handleKeyDown}
                                autofocus="true"
                                variant="onboarding"
                                onChange={(e) => {
                                    const portal = e.target.value
                                    this.setState({ portal, error: false })

                                    // Wait a few MS until we make a request to avoid
                                    // making request on every input change
                                    clearTimeout(this.timer)
                                    this.timer = setTimeout(() => {
                                        this.checkPortalAvailableNames(portal)
                                    }, WAIT_INTERVAL)
                                }}
                                placeholder={this.state.placeholder}
                                defaultValue={this.state.query.portal}
                            />
                            <HelpLink url={HELP_LINKS.PORTAL_NAME}>
                                What makes a good project name?
                            </HelpLink>

                            <Collapse isOpen={!!this.state.error}>
                                {this.state.error && (
                                    <Text>
                                        <span style={{ fontSize: '0.7em' }}>
                                            You need to choose a name
                                        </span>
                                    </Text>
                                )}
                            </Collapse>
                        </Section>

                        <Collapse isOpen={!!this.state.portalName}>
                            {this.state.portalName === '' ? null : (
                                <InfoPanel highlight={`https://${this.state.portalName}`}>
                                    Your Stacker app will be at{' '}
                                </InfoPanel>
                            )}
                        </Collapse>
                    </>
                )}
            </SignupFrame>
        )
    }
}

export default withUser(withRouter(withStack(SignupSetNamePage)))

const InfoPanelText = styled(Text)`
    color: #7f74ca;
`

export const InfoPanel = ({ children, highlight, colours }) => (
    <Section
        noMargin
        style={{
            marginTop: 20,
            padding: '30px 20px',
            background: colours[1],
            opacity: '80%',
            borderLeft: `3px solid ${colours[0]}`,
        }}
    >
        <InfoPanelText margin="none" padding="none" style={{ color: colours[2] }}>
            {children}
        </InfoPanelText>
        {highlight && (
            <InfoPanelText style={{ fontWeight: 'bold', color: colours[3] }}>
                {highlight}
            </InfoPanelText>
        )}
    </Section>
)

InfoPanel.propTypes = {
    colours: PropTypes.arrayOf(PropTypes.string),
}
InfoPanel.defaultProps = {
    colours: ['rgb(111, 92, 238)', 'rgb(111, 92, 238, 0.1)', '#645ba2', '#7f74ca'],
}

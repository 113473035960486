import { useEffect, useRef } from 'react'

import { isEqual } from 'lodash'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import useLDFlags from 'data/hooks/useLDFlags'

import { useCanAccessSupport } from './intercom'
import { bootPylon } from './pylon'

type PylonSettings = {
    email: string
    name: string
    avatar_url: any
    account_id?: string
}

export const usePylon = () => {
    const { user } = useAppUserContext()
    const { workspaceAccount } = useAppContext()
    const canAccessSupport = useCanAccessSupport()
    const currentPylonSettings = useRef<PylonSettings | undefined>()
    const { flags } = useLDFlags()

    const currentUser = user

    useEffect(() => {
        if (!currentUser || !canAccessSupport || flags.cohereChimeEnabled) return

        // Only boot Pylon if user has support access
        const pylonSettings = {
            email: currentUser.email,
            name: currentUser.name || currentUser.email,
            avatar_url: currentUser.avatar,
            ...(workspaceAccount ? { account_id: workspaceAccount._sid } : {}),
        }

        // Only reboot if settings changed
        if (!isEqual(pylonSettings, currentPylonSettings.current)) {
            currentPylonSettings.current = pylonSettings
            bootPylon({
                user: currentUser,
                account: workspaceAccount,
            })
        }
    }, [currentUser, canAccessSupport, workspaceAccount, flags?.cohereChimeEnabled])
}

/* Code Quality: Bad */

import React from 'react'
import { withRouter } from 'react-router-dom'

import styled from '@emotion/styled'
import queryString from 'qs'

import { getUrl, Urls } from 'app/UrlService'
import { withStack } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import { ColorPicker, Container, Icon, Section, Text } from 'legacy/v1/ui'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'
import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import { isStudioDomain } from '../utils'

import AppPreview from './ui/AppPreview'
import SignupFrame, {
    LargerInputLabel,
    LeftSection,
    Logo,
    NextButton,
    NextButtonContainer,
    RightSection,
    Title,
} from './ui/SignupFrame'

class SignupSetThemePage extends React.Component {
    state = {
        color: stackerTheme.brandColor,
        logo: null,
        icon: null,
    }

    UNSAFE_componentWillMount() {
        const query = queryString.parse(this.props.location.search.slice(1))
        this.setState({ query })

        // Boot intercom with user email
        const { user } = this.props
        if (!user) {
            window.location = getUrl(Urls.Register)
        }
    }

    register = () => {
        let query = window.location.search
        query += `${query.startsWith('?') ? '&' : '?'}color=${encodeURIComponent(
            this.state.color
        )}&logo=${this.state.logo}&icon=${this.state.icon}`
        const nextStep = isStudioDomain() ? Urls.Register4 : Urls.AdminNewAppCreate
        this.props.history.push(getUrl(`${nextStep}${query}`))
    }

    render() {
        // Slice to remove the "?"
        return (
            <SignupFrame noPadding>
                <Container noMargin noPadding style={{ height: '100%' }}>
                    <LeftSection>
                        <Logo />

                        <Section style={{ padding: 30 }}>
                            <Title>Customize</Title>
                            <Section margin="medium" padding="none">
                                <LargerInputLabel>Choose your theme color</LargerInputLabel>
                                <ColorPicker
                                    size="30px"
                                    color={this.state.color}
                                    onChange={(color) => this.setState({ color })}
                                    hideInput
                                />
                            </Section>
                            <Section margin="medium" padding="none">
                                <LargerInputLabel>Upload a logo</LargerInputLabel>
                                <Container noMargin noPadding>
                                    <ImageUpload onChange={(logo) => this.setState({ logo })}>
                                        {({ onPick }) =>
                                            this.state.logo ? (
                                                <img
                                                    alt="logo"
                                                    src={this.state.logo}
                                                    height="70"
                                                    onClick={onPick}
                                                />
                                            ) : (
                                                <LogoPlaceholder onClick={onPick}>
                                                    <PlaceholderText>
                                                        <Icon icon="upload" />
                                                    </PlaceholderText>
                                                </LogoPlaceholder>
                                            )
                                        }
                                    </ImageUpload>
                                </Container>
                            </Section>
                            <Section margin="medium" padding="none">
                                <LargerInputLabel>Upload a square icon</LargerInputLabel>{' '}
                                <Container noMargin noPadding>
                                    <ImageUpload onChange={(icon) => this.setState({ icon })}>
                                        {({ onPick }) =>
                                            this.state.icon ? (
                                                <img
                                                    alt="icon"
                                                    src={this.state.icon}
                                                    height="70"
                                                    width="70"
                                                    onClick={onPick}
                                                />
                                            ) : (
                                                <LogoPlaceholder square onClick={onPick}>
                                                    <PlaceholderText>
                                                        <Icon icon="upload" />
                                                    </PlaceholderText>
                                                </LogoPlaceholder>
                                            )
                                        }
                                    </ImageUpload>
                                </Container>
                            </Section>

                            <NextButtonContainer margin="medium" style={{ alignItems: 'right' }}>
                                <NextButton onClick={this.register}>Next step</NextButton>
                            </NextButtonContainer>
                        </Section>
                    </LeftSection>
                    <RightSection>
                        <AppPreview name={this.state.query.portal} color={this.state.color} />
                    </RightSection>
                </Container>
            </SignupFrame>
        )
    }
}

export default withUser(withRouter(withStack(SignupSetThemePage)))

const ImageUpload = ({ onChange, children }) => (
    <ReactFilestack
        options={{
            accept: 'image/*',
        }}
        onSuccess={(value) => onChange(value.filesUploaded[0].url)}
        customRender={children}
    />
)

const LogoPlaceholder = styled('div')`
    height: ${(props) => (props.square ? '50px' : '70px')};
    width: ${(props) => (props.square ? '50px' : '300px')};

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.29), 0 1px 1px #fff, 0 1px 0 #fff;
`

const PlaceholderText = styled(Text)`
    opacity: 0.3;
    letter-spacing: 1px;
    text-transform: uppercase;
`

import settings from 'app/settings'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

const PYLON_TOKEN_URL = 'pylon-token/'

export const _bootPylon = (pylonSettings: ChatSettings, callback?: (pylon: any) => void) => {
    try {
        if (window.Pylon) {
            window.pylon = { chat_settings: pylonSettings }

            const customFields = {
                workspace_type_2: 'classic',
                // see https://docs.usepylon.com/pylon-docs/in-app-chat/javascript-api#window-pylon-setnewissuecustomfields
                // 👇 Add any additional custom fields here
            }

            //@ts-ignore
            window.Pylon('setNewIssueCustomFields', customFields)

            if (callback) callback(window.Pylon)
        } else {
            // if Pylon not yet available, retry every 3s until it is
            setTimeout(() => {
                _bootPylon(pylonSettings, callback)
            }, 3000)
        }
    } catch (err) {
        console.error('Unable to boot Pylon', err)
    }
}

type ChatSettings = {
    app_id: string
    email?: string
    name?: string
    avatar_url?: string
    user_hash?: string
}

type BootPylonProps = {
    user?: any
    account?: any
    callback?: any
    extraSettings?: Record<string, any>
    stackId?: string
}

export const bootPylon = async ({
    user = undefined,
    account = undefined,
    callback = undefined,
    extraSettings = {},
}: BootPylonProps) => {
    // Do not boot pylon for workspace guests
    if (user?.membership_options?.role === 'guest') {
        return
    }

    const { name, email, avatar } = user || {}

    let userHash: string | undefined

    if (email) {
        await fetchWithAuth(PYLON_TOKEN_URL, {
            method: 'GET',
        }).then(
            async (response) => {
                await response.json().then(
                    (obj) => (userHash = obj.token),
                    () => null
                )
            },
            () => null
        )
    }

    const chatSettings = {
        app_id: settings.PYLON_APP_ID,
        email: email,
        name: name || email,
        avatar_url: avatar,
        ...extraSettings,
        ...(userHash ? { email_hash: userHash } : {}),
        ...(account ? { account_id: account._sid } : {}),
    }

    _bootPylon(chatSettings, callback)
}

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        Pylon: unknown
        pylon: unknown
    }
}

/* Code Quality: Good */

import React, { useRef, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import { getUrl, Urls } from 'app/UrlService'
import { withUser } from 'data/wrappers/WithUser'

import { Box, Collapse, Flex, Icon } from 'v2/ui'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import Form from 'ui/forms/Form'
import SubmitButton from 'ui/forms/SubmitButton'

import AuthPageFrame from './stacker-auth/AuthPageFrame'
import { Button, InputForm, Link, Text } from './stacker-auth/AuthUIElements'
import { PasswordApi } from './utils'

function ResetPasswordPage({ history, userActions, match }) {
    const { workspaceAccount } = useAppContext()
    const [email, setEmail] = useUrlParam('email', '')
    const [token] = useUrlParam('token', match.params.authToken)

    const passwordFormContext = useRef()

    const useAuth0 = workspaceAccount && workspaceAccount.optional_features.auth0_enabled

    const [emailSent, setEmailSent] = useState()
    const handleSendEmail = (data) => {
        setEmail(data.email)
        return PasswordApi.resetPasswordSendEmail(data.email).then(() => setEmailSent(true))
    }
    const handleSetPassword = (data) => {
        return PasswordApi.adminResetPassword(token, data.password)
            .then((response) => {
                if (response.status >= 400) {
                    passwordFormContext.current.setError('password', {
                        type: 'manual',
                        message: 'Unable to reset password, token might have expired.',
                    })
                } else {
                    return response.json().then((user) => {
                        return userActions.studioUserLoggedIn(user).then(() => {
                            if (workspaceAccount) {
                                history.push(getUrl(Urls.Home))
                            } else {
                                history.push(getUrl(Urls.Stacks))
                            }
                        })
                    })
                }
            })
            .catch(() => {
                passwordFormContext.current.setError('password', {
                    type: 'manual',
                    message: 'Unable to reset password, try again.',
                })
            })
    }

    const getRedirectQuery = () => {
        let query = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        if (query) {
            // drop these params as they aren't needed past the start
            // off onboarding
            delete query.email
            delete query.status
            delete query.mode

            query = queryString.stringify(query)
        }

        return query
    }

    if (useAuth0) {
        return <Redirect to={getUrl(Urls.Login)} />
    }
    return (
        <AuthPageFrame title={!token ? 'Reset password' : 'Set new password'}>
            <Flex column align="stretch" mb={3}>
                <Collapse isOpen={!!!token}>
                    <Form
                        options={{
                            mode: 'onSubmit',
                            defaultValues: { email },
                        }}
                        onSubmit={handleSendEmail}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <InputForm
                            autoFocus
                            noMargin
                            name="email"
                            type="email"
                            placeholder="Enter email address"
                            errorMessages={{
                                required: 'Please enter your email address',
                                pattern: 'Please enter a valid email address',
                            }}
                            required
                            pattern={/^\S+@\S+$/i}
                        />
                        <Collapse isOpen={!!emailSent}>
                            <Box my={2}>
                                <Icon
                                    icon="emailSolid"
                                    size="smmd"
                                    display="inline"
                                    color="brand.200"
                                />
                                <Text display="inline" ml={2}>
                                    <strong>Check your email</strong> to set the new password.
                                </Text>
                            </Box>
                        </Collapse>
                        <SubmitButton
                            mt={3}
                            buttonSize="sm"
                            as={Button}
                            disabled={false}
                            aria-label="Reset password"
                        >
                            Reset password
                        </SubmitButton>
                    </Form>
                </Collapse>
                <Collapse isOpen={!!token}>
                    <Form
                        ref={passwordFormContext}
                        options={{
                            mode: 'onSubmit',
                        }}
                        onSubmit={handleSetPassword}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <InputForm
                            autoFocus
                            noMargin
                            type="password"
                            name="password"
                            placeholder="Enter new password"
                            errorMessages={{
                                required: 'Please enter a password',
                                minLength: 'Password must be at least 8 characters long',
                            }}
                            minLength={8}
                            required
                        />

                        <SubmitButton
                            mt={3}
                            buttonSize="sm"
                            as={Button}
                            disabled={false}
                            aria-label="Set password"
                        >
                            Set password
                        </SubmitButton>
                    </Form>
                </Collapse>
                <Link to={`${getUrl(Urls.Login)}?${getRedirectQuery()}`} mt={4}>
                    Log in
                </Link>
                <Link to={`${getUrl(Urls.Register)}?${getRedirectQuery()}`} mt={1}>
                    Create an account
                </Link>
            </Flex>
        </AuthPageFrame>
    )
}
ResetPasswordPage.propTypes = {
    history: PropTypes.object.isRequired, // From withRouter
    location: PropTypes.object.isRequired, // From withRouter
}

export default withUser(withRouter(ResetPasswordPage))

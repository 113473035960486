// Overwriting the Image prototype to have the Cohere recording working with the Data Grid.
// The reason behind is that the Data Grid is based on Canvas and Cohere does not work out of the box with canvas.
// The patch below has been provided by the Cohere team. It enables to see the Data Grid in live Cohere session.
// The recorded session still have the Data Grid to be blank because Cohere drops the data. See discussion here:
// https://stacker-app.slack.com/archives/C01AK27QCSG/p1644941607242909?thread_ts=1643799442.912889&cid=C01AK27QCSG
//

import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Auth0Provider } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { basicLogger as LDBasicLogger } from 'launchdarkly-js-client-sdk'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import { SuperTokensWrapper } from 'supertokens-auth-react'
import InlineCreateViewModal from 'v2/views/Create/InlineCreateViewModal'
import DashboardCacheAutoFlush from 'v2/views/Dashboard/DashboardCacheAutoFlush'

import { AccountUserContextProvider } from 'app/AccountUserContext'
import { AppContextProvider } from 'app/AppContextProvider'
import { getCurrentStackId } from 'app/AppContextStore'
import { AppUserContextProvider } from 'app/AppUserContextProvider'
import CustomCss from 'app/CustomCss'
import CustomSripts from 'app/CustomScripts'
import { catchAppErrors } from 'app/ErrorBoundaries'
import MetadataLoading from 'app/MetadataLoading'
import PreserveQuery from 'app/PreserveQuery'
import settings, {
    Auth0Settings,
    DD_RUM_APP_ID,
    DD_RUM_TOKEN,
    GOOGLE_OAUTH_CLIENT_ID,
} from 'app/settings'
import TrialIntercept from 'app/TrialIntercept'
import UserMonitoring from 'app/UserMonitoring'
import queryClient from 'data/reactQueryCache'
import { FirstDataConnectedModal } from 'features/AppSettings/DataSources/FirstDataConnectedModal'
import { SyncDataSourceSchemaModal } from 'features/AppSettings/DataSources/SyncDataSourceSchemaModal'
import { ManageUserSettingsModal } from 'features/AppSettings/Roles/AppSettingsModalUserSettings'
import SessionExpiredModal from 'features/auth/stacker-auth/SessionExpiredModal'
import PlanPickerModal from 'features/billing/PlanPickerModal'
import CommandBar from 'features/commandbar/CommandBarSwitcher'
import { PreviewAsModal } from 'features/core/PreviewingBar'
import { LoadActionObserver } from 'features/loadActions'
import ProfileSettingsModal from 'features/profile/ProfileSettingsModal'
import { initializeSupertokens } from 'features/supertokens/supertokens'
import { LayoutEditorContextProvider } from 'features/utils/LayoutEditorContext'
import { NavContextProvider } from 'features/utils/NavContext'
import AdminSideTray from 'features/workspace/AdminSideTray'
import WorkspaceSettingsModal from 'features/workspace/WorkspaceSettingsModal'
import AnalyticsIdentifier from 'utils/AnalyticsIdentifier'
import { CohereIdentifier } from 'utils/Cohere'
import { FullStoryWrapper } from 'utils/FullStory'
import PerformanceMonitor from 'utils/PerformanceMonitor'

import 'focus-visible'

import { UpgradeAirtableV2Observer } from '../features/AppSettings/DataSources/Airtable/UpgradeAirtableV2Observer'
// Analytics instance
import analytics from '../utils/analytics'

import { AirtableV2UpgradeModal } from './AirtableV2UpgradeModal'
import { AuthenticationWrapper } from './AuthenticationWrapper'
import CustomIntegrations from './CustomIntegrations'
import GlobalModals from './GlobalModals'
import GlobalStyle from './GlobalStyle'
import LDFlagsWatcher from './LDFlagsWatcher'
import MetaDataRefresher from './MetaDataRefresher'
import { PylonIntegration } from './PylonIntegration'
import { StackerDomainRoutes, UserDomainRoutes } from './Routes'
import UserActivityTracker from './UserActivityTracker'
import WelcomeModal from './WelcomeModal'

import 'ui/helpers/reset.css'
import 'ui/theme/GlobalStyles.css'
import '@fortawesome/fontawesome-pro/css/all.css'

// We need to import these globally to use for the icon pickers
library.add(fad, far)
const getUserConfirmation = (message, callback) => {
    console.warn('Confirming')
    window.confirm(callback)
}

initializeSupertokens()

class Root extends Component {
    state = {}

    currentInterval = null

    UNSAFE_componentWillMount() {
        // Wipe everything out from local storage whenever we log in as support to avoid issues with
        // old stored tokens and such.
        if (window.location.href.match(/admin\/support-login\?api_token=[A-Za-z0-9.]+$/)) {
            localStorage.clear()
        }

        // Set up Datadog RUM
        datadogRum.init({
            applicationId: DD_RUM_APP_ID,
            clientToken: DD_RUM_TOKEN,
            site: 'datadoghq.com',
            service: 'stacker-frontend',
            version: settings.VERSION,
            env: settings.DOMAIN,
            sampleRate: 100,
            trackInteractions: false,
            trackSessionAcrossSubdomains: true,
            useSecureSessionCookie: true, // Disable this to test on local instances
        })

        const whitelistUrls = settings.ASSETS_URL ? [new RegExp(settings.ASSETS_URL)] : undefined

        // Set up sentry
        Sentry.init({
            dsn: 'https://40856af2f871463287d5cf1b84da1797@sentry.io/1279513',
            integrations: [new BrowserTracing()],
            environment: settings.DOMAIN,
            release: settings.VERSION,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'UnhandledRejection',
                'Record fetch failed',
                'Error updating permission rule undefined',
            ],
            whitelistUrls,
            beforeSend(event, hint) {
                // Don't report on local instances.
                if (settings.DEBUG) {
                    console.log('SENTRY (suppressed on local instances)', event, hint)
                    return null
                }
                return event
            },
        })
        Sentry.configureScope((scope) => {
            scope.setTag('domain', settings.DOMAIN)
            scope.setTag('stack_id', getCurrentStackId())
            scope.setTag('stack_url', window.location.hostname)
        })

        // Set up our own version checker.
        this.checkVersionTimer()
        localStorage.setItem(
            'stacker_show_unpublished_templates',
            settings.IS_PROD ? 'false' : 'true'
        )

        localStorage.setItem(
            'stacker_show_unpublished_installable_features',
            settings.IS_PROD ? 'false' : 'true'
        )
    }

    checkVersionTimer() {
        fetch('/stackerVersion.txt').then((response) => {
            response.text().then((startingVersion) => {
                localStorage.setItem('stacker_version', startingVersion)
            })
        })

        // Ensure there isn't a new version available - check every 5 mins
        this.currentInterval = window.setInterval(() => {
            fetch('/stackerVersion.txt').then((response) => {
                response.text().then((serverVersion) => {
                    const currentVersion = localStorage.getItem('stacker_version')
                    if (currentVersion && currentVersion !== serverVersion) {
                        localStorage.setItem('stacker_version', serverVersion)
                        window.location.reload(true)
                    }
                })
            })
        }, 300000)
    }

    componentWillUnmount() {
        if (this.currentInterval) {
            clearInterval(this.currentInterval)
        }
        localStorage.setItem('stacker_show_unpublished_templates', 'false')

        localStorage.setItem('stacker_show_unpublished_installable_features', 'false')
    }

    render() {
        const { store } = this.props

        const onStackerStudioDomain =
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'edge.stacker.app' ||
            window.location.hostname === 'crosscheck.stacker.app' ||
            window.location.hostname.startsWith('studio.')

        const routes = catchAppErrors(
            onStackerStudioDomain ? <StackerDomainRoutes /> : <UserDomainRoutes />
        )

        return (
            <SuperTokensWrapper>
                <Auth0Provider
                    {...Auth0Settings}
                    redirectUri={window.location.origin}
                    useRefreshTokens
                    skipRedirectCallback={window.location.pathname !== '/complete-login'}
                >
                    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                        <QueryClientProvider client={queryClient}>
                            <DndProvider backend={HTML5Backend}>
                                <Provider store={store}>
                                    <AuthenticationWrapper>
                                        <ToastContainer />
                                        <BrowserRouter getUserConfirmation={getUserConfirmation}>
                                            <DashboardCacheAutoFlush />
                                            <PerformanceMonitor />
                                            <LDFlagsWatcher />
                                            <AppContextProvider>
                                                <MetadataLoading>
                                                    <AccountUserContextProvider>
                                                        <AppUserContextProvider>
                                                            <FullStoryWrapper />
                                                            <UserActivityTracker />
                                                            <AnalyticsIdentifier />
                                                            <CohereIdentifier />
                                                            <MetaDataRefresher />
                                                            <GlobalStyle>
                                                                <GlobalModals>
                                                                    <NavContextProvider>
                                                                        <LayoutEditorContextProvider>
                                                                            <AdminSideTray />
                                                                            <PreserveQuery />
                                                                            {!onStackerStudioDomain && (
                                                                                <CommandBar />
                                                                            )}
                                                                            <TrialIntercept />
                                                                            <PylonIntegration />
                                                                            <CustomIntegrations />
                                                                            <CustomSripts />
                                                                            <CustomCss />
                                                                            <WorkspaceSettingsModal />
                                                                            <PlanPickerModal />
                                                                            <ProfileSettingsModal />
                                                                            <PreviewAsModal />
                                                                            <SyncDataSourceSchemaModal />
                                                                            <FirstDataConnectedModal />
                                                                            <ManageUserSettingsModal />
                                                                            <InlineCreateViewModal />
                                                                            <ScrollToTop>
                                                                                {routes}
                                                                            </ScrollToTop>
                                                                            <LoadActionObserver />
                                                                            <UserMonitoring />
                                                                            <SessionExpiredModal />
                                                                            <WelcomeModal />
                                                                            <AirtableV2UpgradeModal />
                                                                            <UpgradeAirtableV2Observer />
                                                                        </LayoutEditorContextProvider>
                                                                    </NavContextProvider>
                                                                </GlobalModals>
                                                            </GlobalStyle>
                                                        </AppUserContextProvider>
                                                    </AccountUserContextProvider>
                                                </MetadataLoading>
                                            </AppContextProvider>
                                        </BrowserRouter>
                                    </AuthenticationWrapper>
                                </Provider>
                            </DndProvider>
                        </QueryClientProvider>
                    </GoogleOAuthProvider>
                </Auth0Provider>
            </SuperTokensWrapper>
        )
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
}

export default withLDProvider({
    clientSideID: settings.LAUNCH_DARKLY.clientSideId,
    context: {
        kind: 'user',
        key: 'anonymous',
        anonymous: true,
    },
    options: {
        logger: LDBasicLogger({ level: settings.LAUNCH_DARKLY.logLevel }),
    },
})(Root)

class _ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    UNSAFE_componentWillMount() {
        this.unlistenHistory = this.props.history.listen(() => {
            analytics.page()
        })
    }

    componentWillUnmount() {
        this.unlistenHistory()
    }

    render() {
        return this.props.children
    }
}

_ScrollToTop.propTypes = {
    children: PropTypes.element.isRequired,
    location: PropTypes.object.isRequired, // From withRouter
}

const ScrollToTop = withRouter(_ScrollToTop)

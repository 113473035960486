import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { buildUrl } from 'data/utils/utils'
import { withAccounts } from 'data/wrappers/WithAccounts'
import { withUser } from 'data/wrappers/WithUser'
import { hexToString } from 'utils/utils'

import { Box, Collapse, Flex, Icon } from 'v2/ui'

import analytics from '../../../utils/analytics'

import { Button, Link, Text } from './AuthUIElements'
import EmailVerificationForm from './EmailVerificationForm'
import GoogleLoginButtonBase, { StyledGoogleLoginButton } from './GoogleLoginButton'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'

const LoginStatus = {
    REGISTERED: 'registered',
    EXISTING: 'existing',
    EXISTING_GOOGLE: 'existing_google',
}

export const SignInFlowInternal = withRouter(
    ({ isSignUp, userActions, history, user, redirect, accounts }) => {
        const query = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        const { workspaceAccount } = useAppContext()
        const [redirecting, setRedirecting] = useState()
        const [invalidWorkspace, setInvalidWorkspace] = useState()
        const [email, setEmail] = useState(query.email)
        const { isFetching: loadingMetadata } = useInitialMetadata()
        const [loginComplete, setLoginComplete] = useState()
        const showRegisterLink = !workspaceAccount
        const [emailOptIn, setEmailOptIn] = useState(true)

        const name = query.name

        const [failureMessage, setFailureMessage] = useState()

        const { isAuthenticated: isAuthenticatedViaAuth0, logout: logoutAuth0 } = useAuth0()

        const logErrorMessage = (message, error) => {
            Sentry.withScope((scope) => {
                if (user) {
                    const { name, email } = user
                    scope.setExtra('email', email)
                    scope.setExtra('name', name)
                }
                const query = queryString.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                })

                const options = { signup_params: query }

                scope.setExtra('options', options)
                if (error) {
                    scope.setExtra('message', message)

                    Sentry.captureException(error)
                } else {
                    Sentry.captureMessage(message)
                }
            })
        }
        const getRedirectQuery = () => {
            let query = queryString.parse(window.location.search, {
                ignoreQueryPrefix: true,
            })
            if (query) {
                // drop these params as they aren't needed past the start
                // off onboarding
                delete query.email
                delete query.name
                delete query.status

                query = queryString.stringify(query)
            }

            return query
        }

        const isOnboardingComplete = (accounts) => {
            const onboardingSteps = accounts && accounts[0]?.options?.onboarding_status

            return (
                (accounts && onboardingSteps?.tables_set) ||
                (accounts && onboardingSteps?.stack_set && onboardingSteps?.purpose_set)
            )
        }

        const getContinueUrl = (user, accounts) => {
            const template = query.template
            const type = query.type
            // If we have a template, or a data source supplied
            // go straight into the stack creation flow
            if (template || type) {
                return `${getUrl(Urls.Register2)}?${getRedirectQuery()}`
            }

            if (workspaceAccount) {
                if (user?.membership_options?.profile_pending) {
                    return getUrl(Urls.EditProfile)
                }
                return getUrl(Urls.Root)
            }

            if (isOnboardingComplete(accounts) && accounts[0]?.base_url) {
                return `https://${accounts[0].base_url}/stacks`
            }

            return `${getUrl(Urls.Stacks)}?${getRedirectQuery()}`
        }

        // Whenever our login state changes...
        useEffect(() => {
            // if we are logged in, and we have a list of accounts, and the Query
            // is asking us to redirect to a specific workspace account, try to do that now
            if (query.workspace && query.domain && accounts && user) {
                const account = accounts.find((account) => account._sid === query.workspace)

                if (account) {
                    window.location.assign(
                        `//${query.domain}/auth?token=${user.api_token}&r=${encodeURIComponent(
                            query.r
                        )}`
                    )
                } else {
                    // No matching account found for the current user. Show message.
                    setInvalidWorkspace(query.domain)
                }

                // Otherwise, if the user has just logged in and we're not still waiting
                // for meta-data to load, then do the post login redirect now
            } else if (
                (loginComplete || status === LoginStatus.REGISTERED) &&
                user &&
                !loadingMetadata
            ) {
                // We want to clear out the status URL param if it's set to Registered,
                // so if the user hits back after the redirect, they don't just get redirected again.
                if (status === LoginStatus.REGISTERED) {
                    setStatus(null, true)
                }
                if (redirect) {
                    const joiner = redirect.indexOf('?') === -1 ? '?' : '&'

                    // Instead of just redirecting here, we need to set the redirect
                    // state flag, as that allows us to show a loading state even
                    // after this component gets completely recreated due to the
                    // logged-in state change
                    userActions.setRedirect(`${redirect}${joiner}auth_token=${user.auth_token}`)
                } else {
                    const url = getContinueUrl(user)

                    if (url && (!url.startsWith('/') || url.startsWith('//'))) {
                        // Instead of just redirecting here, we need to set the redirect
                        // state flag, as that allows us to show a loading state even
                        // after this component gets completely recreated due to the
                        // logged-in state change
                        userActions.setRedirect(url)
                    } else {
                        history.push(getContinueUrl(user))
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [accounts, user, loginComplete, loadingMetadata])

        // If we have a redirect instruction coming in from the
        // redux store, then we need to redirect now.
        useEffect(() => {
            if (redirect) {
                if (!window.Pylon && user) {
                    logErrorMessage('Redirecting user without booting pylon')
                }
                // Wait 1 second before redirecting, so we can update Pylon
                // There is no callback and updateIntercom doesn't return any Promise, so this is the only way
                setRedirecting(true)
                setTimeout(() => {
                    window.location.href = redirect
                    userActions.setRedirect(null)
                }, 1000)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [redirect])

        const onRegistered = async (user) => {
            const { name, email, _sid, account_id } = user

            try {
                analytics.identify(_sid, {
                    email,
                    name,
                })

                analytics.plugins?.segment?.group(account_id, {
                    group_type: 'account id',
                    group_value: account_id,
                })

                analytics.track('user registration completed', {
                    workspace_id: account_id,
                    user_id: _sid,
                    event_description: 'New user registration was completed successfully',
                    event_category: 'user',
                })
            } catch (e) {
                logErrorMessage('Exception when sending account created event on signup', e)
            }

            try {
                await userActions.studioUserLoggedIn(user)
                setStatus(LoginStatus.REGISTERED, true)
                setLoginComplete(true)
            } catch (e) {
                logErrorMessage('Exception when logging in user', e)
            }
        }

        const onLoggedIn = (user, isSignUp) => {
            userActions.studioUserLoggedIn(user).then(() => {
                // User was trying to sign up, but already had an acccount and are now logged in,
                // we won't redirect. We'll display the appropriate message so they aren't confused.
                if (!isSignUp) {
                    // Don't do anything here. Once the metadata
                    // refetches due to the user being authenticated
                    // there is a useEffect above that will handle
                    // redirecting to the right location.
                    setLoginComplete(true)
                }
            })
        }

        const handleRegisterSuccess = (user) => {
            setFailureMessage(null)
            if (user.is_existing_user) {
                analytics.track('user already exists', {
                    workspace_id: user?.account_id,
                    user_id: user?._sid,
                    event_description: 'New user registration exists',
                    event_category: 'user',
                })
                onLoggedIn(user, isSignUp)
            } else {
                onRegistered(user)
            }
        }

        const handleFailure = (response, email) => {
            if (response && response.exception) {
                if (response.exception === 'UserAlreadyExists') {
                    setEmail(email)
                    if (get(response, 'details.provider') === 'google') {
                        setStatus(LoginStatus.EXISTING_GOOGLE)
                    } else {
                        setStatus(LoginStatus.EXISTING)
                    }
                } else if (response.exception === 'LoginFailed') {
                    if (get(response, 'details.existing_user_provider') === 'google') {
                        setEmail(email)
                        setStatus(LoginStatus.EXISTING_GOOGLE)
                    }
                } else if (email === undefined) {
                    setFailureMessage(
                        'This Google account is not associated with this app or workspace.'
                    )
                } else {
                    setFailureMessage(response.error)
                }
            } else {
                // Not sure what happened. This is the catch all, so we just want to log it.
                Sentry.withScope((scope) => {
                    scope.setExtra('response', response)
                    scope.setLevel('error')
                    Sentry.captureMessage('Unable to register')
                })
            }
        }

        // Handles the case where an google signin fails and the user is redirected
        // back here with an error message in the URL
        const queryError = query.error
        useEffect(() => {
            if (queryError) {
                const { error, ...rest } = query
                history.replace(buildUrl(window.location.pathname, rest))
                const errorJson = hexToString(error)
                const response = JSON.parse(errorJson)
                handleFailure(response)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [queryError])

        const setStatus = (status, replace) => {
            const query = queryString.parse(window.location.search, {
                ignoreQueryPrefix: true,
            })
            if (status) {
                query.status = status
            } else {
                delete query.status
            }
            const url = buildUrl(window.location.pathname, query)

            if (replace) {
                history.replace(url)
            } else {
                history.push(url)
            }
        }

        const handleLogout = (e) => {
            userActions.logOutAllUsers()

            if (isAuthenticatedViaAuth0) {
                // even if we don't have a user, as long as we have an auth0 session we want to run through their logout.
                logoutAuth0({ returnTo: window.location.href })
            } else {
                setStatus(null)
                e.preventDefault()
            }
        }

        const continueUrl = getContinueUrl(user, accounts)
        const isLoading = loadingMetadata || redirecting
        const status = query.status
        const template = query.template
        const showShowExistingAccount = !isLoading && !user && status === LoginStatus.EXISTING
        const isExistingGoogleAccount =
            !isLoading && !user && status === LoginStatus.EXISTING_GOOGLE
        const showLogin = !isLoading && !user && !isSignUp && !status
        const showSignUp = !isLoading && !status && !user && isSignUp

        return (
            <>
                <Collapse isOpen={!!isLoading}>
                    <Flex height="100%" align="center" column py="100px">
                        <Spinner />
                    </Flex>
                </Collapse>
                <Collapse isOpen={!!failureMessage}>
                    <Text my={6} bg="#22222233" p={4} rounded="md">
                        <Icon icon="alert" mr={2} display="inline" />
                        {failureMessage}
                    </Text>
                </Collapse>
                <Collapse isOpen={showShowExistingAccount}>
                    {showShowExistingAccount && (
                        <>
                            <ExistingAccountMessage email={email} />
                            <LoginForm
                                onSuccess={onLoggedIn}
                                onRejected={handleFailure}
                                email={email}
                                onLogIn={userActions.logInStudioUser}
                                showRegisterLink={showRegisterLink}
                            />
                        </>
                    )}
                </Collapse>
                <Collapse isOpen={!!(user && !isLoading)}>
                    <WelcomeBackMessage
                        email={user && user.email}
                        template={template}
                        continueUrl={continueUrl}
                        handleLogout={handleLogout}
                        isSignUp={isSignUp}
                        invalidWorkspace={invalidWorkspace}
                    />
                </Collapse>
                <Collapse isOpen={!!showSignUp}>
                    {showSignUp && (
                        <Flex align="stretch" column>
                            <GoogleLoginButton
                                onSuccess={handleRegisterSuccess}
                                isSignUp
                                onRejected={handleFailure}
                            />

                            <Text mt={4} alignSelf="center">
                                - or -
                            </Text>
                            {workspaceAccount ? (
                                <EmailVerificationForm
                                    onSuccess={handleRegisterSuccess}
                                    onRejected={handleFailure}
                                    email={email}
                                    isSignUp
                                />
                            ) : (
                                <RegisterForm
                                    onSuccess={handleRegisterSuccess}
                                    onRejected={handleFailure}
                                    email={email}
                                    name={name}
                                    emailOptIn={emailOptIn}
                                    setEmailOptIn={setEmailOptIn}
                                />
                            )}
                            <Text mt={3} style={{ alignSelf: 'center' }}>
                                <span>Already have an account? </span>
                                <Link to={`${getUrl(Urls.Login)}?${getRedirectQuery()}`}>
                                    Login
                                </Link>
                            </Text>
                            {!workspaceAccount && (
                                <Text mt={6} size="xs" color="grey.300">
                                    By signing up, you agree to our{' '}
                                    <Link
                                        href={Urls.TermsOfService}
                                        color="grey.300"
                                        textDecoration="underline"
                                    >
                                        Terms of Service
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                        href={Urls.PrivacyPolicy}
                                        color="grey.300"
                                        textDecoration="underline"
                                    >
                                        Privacy Policy
                                    </Link>
                                </Text>
                            )}
                        </Flex>
                    )}
                </Collapse>
                <Collapse isOpen={showLogin}>
                    {showLogin ? (
                        <Flex align="stretch" column>
                            <GoogleLoginButton onSuccess={onLoggedIn} onRejected={handleFailure} />

                            <Text my={6} alignSelf="center">
                                - or -
                            </Text>
                            <LoginForm
                                onSuccess={onLoggedIn}
                                onRejected={handleFailure}
                                email={email}
                                onLogIn={userActions.logInStudioUser}
                                showRegisterLink={showRegisterLink}
                            />
                        </Flex>
                    ) : null}
                </Collapse>
                <Collapse isOpen={isExistingGoogleAccount}>
                    {isExistingGoogleAccount && (
                        <ExistingGoogleAccount
                            email={email}
                            onSuccess={onLoggedIn}
                            onRejected={handleFailure}
                        />
                    )}
                </Collapse>
            </>
        )
    }
)

const WelcomeBackMessage = ({
    email,
    template,
    continueUrl,
    handleLogout,
    isSignUp,
    invalidWorkspace,
}) => {
    return (
        <Box>
            <Text variant="authenticationHeader">Welcome back!</Text>
            {invalidWorkspace && (
                <Text my={6} bg="gray.100" p={4} rounded="md">
                    <Icon icon="alert" mr={2} display="inline" /> You do not have access to the
                    workspace at <strong>{invalidWorkspace}</strong>.
                </Text>
            )}
            <Text variant="authenticationText" my={6}>
                {isSignUp ? (
                    <>
                        You already have an account registered under <strong>{email}</strong>.{' '}
                    </>
                ) : (
                    <>
                        You are currently logged in as <strong>{email}</strong>.{' '}
                    </>
                )}
            </Text>
            <Button
                variant="authenticationPrimary"
                buttonSize="sm"
                href={continueUrl}
                my={4}
                openInNewTab={false}
            >
                {template ? 'Continue' : 'Continue to my account'}
            </Button>
            <Text my={4}>
                Not your account?{' '}
                <a href="#" onClick={handleLogout}>
                    Sign out
                </a>
            </Text>
        </Box>
    )
}

const ExistingAccountMessage = ({ email }) => {
    return (
        <Box>
            <Text variant="authenticationHeader">Welcome back!</Text>
            <Text variant="authenticationText" my={6}>
                You already have an account registered under <strong>{email}</strong>.
            </Text>
        </Box>
    )
}

const ExistingGoogleAccount = ({ email, onSuccess, onRejected }) => {
    return (
        <Box>
            <Text variant="authenticationHeader">Welcome back!</Text>
            <Text variant="authenticationText" my={6}>
                You previously registered <strong>{email}</strong> via Google.
            </Text>
            <Text variant="authenticationText" my={6}>
                Click below to continue.
            </Text>
            <GoogleLoginButton onSuccess={onSuccess} onRejected={onRejected} />
        </Box>
    )
}

export const GoogleLoginButton = ({ isSignUp, ...props }) => {
    const { workspaceAccount } = useAppContext()
    const text = isSignUp ? 'Sign up with Google' : 'Sign in with Google'
    const return_url = `${window.location.origin}/auth`
    const failUrl = isSignUp
        ? `${window.location.origin}/register`
        : `${window.location.origin}/login`

    // If we're logging on a workspace domain, then instead of
    // doing client-side google auth, we do the server side flow.
    if (workspaceAccount) {
        return (
            <StyledGoogleLoginButton
                href={`${
                    settings.OAUTH_REDIRECT_URL
                }authenticate/google?return_url=${encodeURIComponent(
                    return_url
                )}&fail_url=${encodeURIComponent(failUrl)}`}
                openInNewTab={false}
            >
                {text}
            </StyledGoogleLoginButton>
        )
    }
    return <GoogleLoginButtonBase isSignUp={isSignUp} text={text} {...props} />
}

export default withAccounts(withUser(SignInFlowInternal))

import React, { useCallback, useMemo } from 'react'

import { assertIsDefined } from 'data/utils/ts_utils'
import useTrack from 'utils/useTrack'

import {
    EditableFieldWrapperBase,
    EnabledFieldConfigOptions,
} from '../Common/EditableFieldWrapper/EditableFieldWrapperBase'
import type { EditableElementWrapperProps } from '../Common/types'

import { useEditModeListContext } from './EditModeListContextProvider'

type EditableListFieldWrapperProps = React.PropsWithChildren<
    EditableElementWrapperProps & {
        fieldId?: string
        objectId?: string
        viewOptions?: ListViewOptions
        setConfig?: (value: Partial<ListViewOptions>) => void
        hideLabelOptions?: boolean
    }
>
const EditableListFieldWrapperInner: React.FC<EditableListFieldWrapperProps> = ({
    fieldId,
    viewOptions,
    setConfig,
    hideLabelOptions,
    ...props
}) => {
    const {
        hoveredEditorFieldId,
        activeEditorFieldId,
        setHoveredEditorFieldId,
        setActiveEditorFieldId,
    } = useEditModeListContext()

    const display = viewOptions?.display
    const { track } = useTrack()
    const enabledOptions = useMemo<EnabledFieldConfigOptions>(() => {
        return {
            label: !hideLabelOptions,
            hideLabel: display !== 'inbox' && display !== 'rows',
            wrapText: !display || display === 'table' || display === 'inbox',
        }
    }, [display, hideLabelOptions])

    const fieldViewConfig = useMemo(
        () => viewOptions?.columns.find(({ fieldId: columnFieldId }) => columnFieldId === fieldId),
        [fieldId, viewOptions]
    )

    const onChange = useCallback(
        (patch: any): void => {
            setConfig?.({
                columns:
                    viewOptions?.columns.map((fieldViewConfig) => {
                        if (fieldViewConfig.fieldId === fieldId) {
                            return { ...fieldViewConfig, ...patch }
                        }

                        return fieldViewConfig
                    }) ?? [],
            })

            track('WIP - Front end - List view field options - Saved', { changes: patch })
        },
        [fieldId, setConfig, viewOptions?.columns, track]
    )

    const proceedHideField = useCallback(() => {
        setConfig?.({
            columns:
                viewOptions?.columns.filter(
                    ({ fieldId: columnFieldId }) => columnFieldId !== fieldId
                ) ?? [],
            showAllFields: false,
        })
    }, [fieldId, setConfig, viewOptions?.columns])

    const onHideField = useCallback((): void => {
        track('WIP - Front end - List view field options - Hide Field - Clicked')

        proceedHideField()
    }, [track, proceedHideField])

    const onEditorHoverStart = useCallback(() => {
        setHoveredEditorFieldId(fieldId)
    }, [setHoveredEditorFieldId, fieldId])

    const onEditorHoverEnd = useCallback(() => {
        setHoveredEditorFieldId(undefined)
    }, [setHoveredEditorFieldId])

    const onEditorActiveStart = useCallback(() => {
        setActiveEditorFieldId(fieldId)
    }, [setActiveEditorFieldId, fieldId])

    const onEditorActiveEnd = useCallback(() => {
        setActiveEditorFieldId(undefined)
    }, [setActiveEditorFieldId])

    assertIsDefined(fieldViewConfig)

    return (
        <EditableFieldWrapperBase
            display={display}
            fieldViewConfig={fieldViewConfig}
            fieldId={fieldId}
            onChange={onChange}
            onHideField={onHideField}
            enabledOptions={enabledOptions}
            onHoverStart={onEditorHoverStart}
            onHoverEnd={onEditorHoverEnd}
            onEditorOpen={onEditorActiveStart}
            onEditorClose={onEditorActiveEnd}
            highlight={[hoveredEditorFieldId, activeEditorFieldId].includes(fieldId)}
            {...props}
        />
    )
}
const EditableListFieldWrapperCheckInputs: React.FC<EditableListFieldWrapperProps> = (props) => {
    if (!props.fieldId || !props.viewOptions) {
        return <>{props.children}</>
    }

    return <EditableListFieldWrapperInner {...props} />
}
export const EditableListFieldWrapper = React.memo(EditableListFieldWrapperCheckInputs)

export default EditableListFieldWrapper

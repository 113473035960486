import React, { memo, useCallback, useContext, useEffect } from 'react'
import useScrollbarSize from 'react-scrollbar-size'

import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import AppUsercontext from 'app/AppUserContext'
import { catchComponentErrors } from 'app/ErrorBoundaries'
import MetadataLoading from 'app/MetadataLoading'
import useLDFlags from 'data/hooks/useLDFlags'
import { isImpersonatingEndUser } from 'data/utils/isAdmin'
import { withStack, withStacks } from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { useIsEditingBlankPages } from 'features/blank-pages/useIsEditingBlankPages'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import HeroContainer from 'features/core/HeroContainer'
import SideNav from 'features/core/nav/SideNav'
import PoweredBy from 'features/core/PoweredBy'
import { HeaderContextProvider } from 'features/utils/HeaderContext'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { NavContext } from 'features/utils/NavContext'
import { SIDE_PANE_DEFAULT_WIDTH } from 'features/workspace/AdminSideTray/constants'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import SidebarWorkspace from 'features/workspace/Sidebar'
import CohereVoiceWidget from 'utils/CohereVoiceWidget'
import StreamApp from 'utils/StreamApp'

import { Box, LoadingSplash } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDimension from 'v2/ui/utils/useDimension'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import Header from './nav/Header'
import { HEADER_HEIGHT } from './nav/navUtils'
import { SECOND_NAV_HEIGHT } from './nav/SecondaryNavigation'
import { BANNER_HEIGHT } from './appBannerConstants'
import AppBannerService from './AppBannerService'
import { APP_BAR_WIDTH, EDIT_MODE_GUTTER } from './frameConstants'
import { getLeftOffset } from './getLeftOffset'
import { useIsBannerShowing } from './useIsBannerShowing'

export const FrameContext = React.createContext()

export const Frame = ({
    isStudioUser,
    children,
    context,
    hideHeader,
    isEditing,
    stackOptions,
    width,
    workspaceAccount,
    stack,
    page,
    view,
    background,
    ignoreLoadingState,
    stacks,
    studioUser,
}) => {
    const workspacesMode = isStudioUser && !isImpersonatingEndUser() && workspaceAccount
    // If users are being synced from the connected user lists as auths instead of
    // stacker users, then we use the v4 settings for external access (as differentiated
    // from the old customer access mode where external access used the old v3 settings)
    const useV4SettingsForExternalAccess = stack?.options?.sync_users_as_auths
    const { setContextInfo: setSlidingPaneContextInfo } = useSlidingPane()
    const isMobile = useIsMobile()
    const sideNavV3 =
        stack?._sid &&
        get(stackOptions, useV4SettingsForExternalAccess ? 'vertical_nav_v4' : 'vertical_nav')
    const sideNavV4 = stack?._sid && get(stackOptions, 'vertical_nav_v4', true)
    const showSideNav = (workspacesMode ? sideNavV4 : sideNavV3) && !isMobile
    const showSideNavIgnoreMobile = workspacesMode ? sideNavV4 : sideNavV3
    const [display, setDisplay] = React.useState(view?.options?.display)
    const [headerState, setHeaderState] = React.useState({
        isVisible: !(hideHeader || showSideNav),
        isSecondaryHeaderVisible: false, // Gets determined inside header
    })

    const filteredStacks = stacks?.filter((stack) => stack.account_id === workspaceAccount?._sid)
    const isGuest = studioUser?.membership_options?.role === 'guest'
    const shouldHideAppBarForGuest = isGuest && filteredStacks?.length === 1
    const [isBannerShowing] = useIsBannerShowing()

    useEffect(() => {
        setHeaderState((state) => {
            return { ...state, isVisible: !(hideHeader || showSideNav) }
        })
    }, [hideHeader, showSideNav])

    useEffect(() => {
        setSlidingPaneContextInfo({
            showSideNav,
            isSecondaryHeaderVisible: headerState.isSecondaryHeaderVisible,
        })
    }, [showSideNav, headerState, setSlidingPaneContextInfo])

    let headerHeight = 0
    if (headerState.isVisible) {
        headerHeight =
            HEADER_HEIGHT + (headerState.isSecondaryHeaderVisible ? SECOND_NAV_HEIGHT : 0)
        if (isBannerShowing) {
            headerHeight += 36
        }
    }
    const frameContext = React.useMemo(
        () => ({
            onDisplayChange: setDisplay,
            headerHeight: headerHeight,
        }),
        [setDisplay, headerHeight]
    )
    const isInbox = display === 'inbox' && !isMobile
    const isDashboard = view?.type === 'dashboard'
    const { isOpen: isAppBarOpen, onClose: closeDrawer, onToggle } = useContext(NavContext)
    const isAppBarAvailable = workspacesMode && !isMobile && !shouldHideAppBarForGuest
    const showAppBar = isAppBarAvailable

    const showBreadcrumbs = stackOptions?.showBreadcrumbs ?? false
    const { isLoading, authStateKey } = useContext(AppUsercontext)

    const [backdropWidth, setBackdropWidth] = React.useState()

    const { selectedStack } = useAppContext()
    const isV3App = selectedStack && !selectedStack?.options?.workspace_app

    const { hasRight } = useAccountUserContext()
    const { flags } = useLDFlags()

    const showCohereVoiceWidget =
        !isV3App && hasRight(Rights.ContactSupport) && flags.cohereChimeEnabled

    const toggleDrawer = (e) => {
        onToggle()
        e.preventDefault()
    }

    const setHeaderStateCallback = React.useCallback(
        (updatedState) =>
            setHeaderState((lastState) => ({
                ...lastState,
                isSecondaryHeaderVisible: updatedState,
            })),
        []
    )
    const defaultWidth = isDashboard || isInbox ? '100%' : 1000
    const contentWidth = width || defaultWidth

    const padding = isInbox ? '0' : '0px 10px 50px'

    const isEditingBlankPages = useIsEditingBlankPages()
    const portalContext = useContext(LayoutEditorContext)

    //close active portal if closing editor
    if (!isEditing && !isEditingBlankPages && portalContext.activeEditor) {
        portalContext.closeEditor()
    }

    // Close the active editor on unmount
    useEffect(() => {
        return () => portalContext.closeEditor()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FrameContext.Provider value={frameContext}>
            <MetadataLoading onlyShowOnInitialLoad={false}>
                <StreamApp>
                    <Backdrop
                        showSidebar={isEditing}
                        showAppBar={showAppBar}
                        showSideNav={showSideNav}
                        background={background}
                        isMobile={isMobile}
                        shouldHideAppBarForGuest={shouldHideAppBarForGuest}
                        setBackdropWidth={setBackdropWidth}
                    >
                        <DefaultHeaderMeta page={page} view={view}></DefaultHeaderMeta>

                        <SideNavBar
                            context={context}
                            showAppBar={showAppBar}
                            showSideNav={showSideNav}
                            workspacesMode={workspacesMode}
                        />

                        {/* All app banners */}
                        {workspaceAccount && (
                            <AppBannerService
                                leftOffset={APP_BAR_WIDTH}
                                showingAppBar={showAppBar}
                            />
                        )}

                        {/* if we're in a workspace, and we're impersonating
                                    or we just installed a template
                                    then we need to insert some space at the top of the page since
                                    we have a fixed banner above. */}
                        {workspaceAccount && isBannerShowing && (
                            <div style={{ height: BANNER_HEIGHT }} />
                        )}
                        <Header
                            hideHeader={!headerState.isVisible}
                            onSecondaryHeaderStateChange={setHeaderStateCallback}
                            context={context}
                            hideNavItems={showSideNavIgnoreMobile}
                            onMobileMenuClick={
                                workspacesMode || showSideNavIgnoreMobile ? toggleDrawer : null
                            }
                            workspaceAccount={workspaceAccount}
                        />
                        {(workspacesMode || showSideNavIgnoreMobile) && isMobile && (
                            <SideNavDrawer
                                isOpen={isAppBarOpen}
                                showAppBar={showAppBar}
                                onClose={closeDrawer}
                                workspacesMode={workspacesMode}
                                showSideNav={(sideNavV4 || sideNavV3) && !isLoading}
                            />
                        )}
                        {/* Would ideally like to replace this with a generic 'Box' UI component  */}

                        {showCohereVoiceWidget && <CohereVoiceWidget />}

                        {isLoading && !ignoreLoadingState ? (
                            <LoadingSplash />
                        ) : (
                            <>
                                <HeaderContextProvider>
                                    <HeroContainer
                                        hide={isInbox}
                                        backdropWidth={backdropWidth}
                                        showBreadcrumbs={showBreadcrumbs}
                                    />

                                    {/* we want to completely recreate the components below this level if our auth state changes
                                            (ie., impersonation/preview status changes): so we key this div with the authStateKey provided
                                            from AppUserContext */}
                                    <div
                                        className={STYLE_CLASSES.CONTENT}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flex: '1 0 auto',
                                            padding: padding,
                                            overflow: 'hidden',
                                        }}
                                        key={authStateKey}
                                    >
                                        <div
                                            style={{
                                                padding: padding,
                                                width: contentWidth,
                                                maxWidth: '100%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                        >
                                            {catchComponentErrors(children)}
                                        </div>
                                    </div>
                                </HeaderContextProvider>
                            </>
                        )}
                        {!isInbox && <PoweredBy />}
                    </Backdrop>
                </StreamApp>
            </MetadataLoading>
        </FrameContext.Provider>
    )
}

Frame.propTypes = {
    children: PropTypes.node,
    context: PropTypes.object,
    hideHeader: PropTypes.bool,
}
Frame.defaultProps = {
    children: null,
    context: {},
    hideHeader: false,
}

export default withUser(withStacks(withStack(Frame)))

const BackdropStyled = styled('div')`
    background-color: ${(props) =>
        props.background ? props.background : props.theme.backgroundColor};
    width: ${(props) =>
        props.leftMargin || props.rightMargin
            ? `calc(100% - ${props.leftMargin || '0px'} - ${props.rightMargin || '0px'})`
            : '100%'};
    height: min-content;
    min-height: 100vh;
    margin-left: ${(props) => props.leftMargin};
    margin-right: ${(props) => props.rightMargin};
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding-right: ${(props) => (props.rightPadded ? `${EDIT_MODE_GUTTER}px` : 0)};
    transition: margin-left 400ms;
`
const Backdrop = ({
    showSidebar,
    showAppBar,
    showSideNav,
    background,
    children,
    shouldHideAppBarForGuest,
    setBackdropWidth,
    shouldAppBarOverlay,
}) => {
    const {
        state: { animationComplete },
    } = useSlidingPane()

    // Use the non-admin call here as the admin call has a delay before it returns true
    // (waiting on roles to load, etc) and all of the conditions below just need to know if
    // we're in an SB app, period.
    const { width } = useScrollbarSize()
    const { isOpen } = useEditMode()
    const backdropRef = React.useRef()
    const { width: backdropWidth } = useDimension(backdropRef)

    useEffect(() => {
        setBackdropWidth(backdropWidth)
    }, [backdropWidth, setBackdropWidth])

    const showRightOffsetForEditLayout = showSidebar && animationComplete
    const showRightOffset = showRightOffsetForEditLayout
    const rightOffset = SIDE_PANE_DEFAULT_WIDTH

    return (
        <BackdropStyled
            className="BackdropStyled"
            rightPadded={isOpen && showSideNav}
            leftMargin={
                shouldAppBarOverlay
                    ? 0
                    : `${getLeftOffset(showAppBar, showSideNav, false, shouldHideAppBarForGuest)}px`
            }
            rightMargin={
                showRightOffset
                    ? // subtract scrollbar width and an extra 10px to account for the
                      // rounded corners of the side pane
                      `${rightOffset - width - 10}px`
                    : '0px'
            }
            background={background}
            ref={backdropRef}
        >
            {children}
        </BackdropStyled>
    )
}

const SideNavBar = memo(function SideNavBar({ context, showAppBar, showSideNav, workspacesMode }) {
    const width = getLeftOffset(showAppBar, showSideNav)
    return (
        <SideNavBarContainer
            className={width > 0 && (showAppBar || showSideNav) ? 'expanded' : 'closed'}
            left={0}
            align="stretch"
            position="fixed"
            contentWidth={width}
            height="100%"
            background="black"
            zIndex={99}
        >
            {workspacesMode ? (
                <SidebarWorkspace showSideNav={showSideNav} />
            ) : (
                <SideNav context={context} showAppBar={showAppBar} showSideNav={showSideNav} />
            )}
        </SideNavBarContainer>
    )
})

const SideNavDrawer = React.memo(({ isOpen, onClose, workspacesMode, showSideNav, showAppBar }) => {
    const width = getLeftOffset(showAppBar, showSideNav)
    const onClickNavItem = useCallback(
        ({ isFolder }) => {
            if (!isFolder) onClose()
        },
        [onClose]
    )

    return (
        <Drawer key="drawer" placement="left" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent maxWidth={width}>
                {workspacesMode ? (
                    <SidebarWorkspace showSideNav={showSideNav} onClickNavItem={onClickNavItem} />
                ) : (
                    <SideNav showSideNav={showSideNav} />
                )}
            </DrawerContent>
        </Drawer>
    )
})

const SideNavBarContainer = styled(Box)`
    &.closed {
        width: 0;
        overflow: hidden;
    }

    &.expanded {
        width: ${(p) => p.contentWidth}px;
        z-index: 200;
    }

    transition: width 400ms;
`

import React from 'react'

import { CircularProgress } from '@chakra-ui/react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import { openPlanPickerModal } from 'features/utils/__hackyGlobalModalControls'

import { Button, Progress } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import analytics from '../../utils/analytics'

const TrialProgress = ({
    showPlanButton,
    circular,
    children,
    size,
    alwaysReturnChildren,
    showRemaining,
    onClose,
}) => {
    const { workspaceAccount } = React.useContext(AppContext)
    const { hasRight } = useAccountUserContext()

    const nullReturn = alwaysReturnChildren ? children : null
    const TOTAL_TRIAL_DAYS = workspaceAccount?.total_trial_days || 30

    if (
        !workspaceAccount ||
        !workspaceAccount?.trial_ui_enabled ||
        !workspaceAccount?.is_using_trial
    )
        return nullReturn

    const daysRemaining = workspaceAccount?.trial_days_remaining ?? 0

    let percentage = 100
    if (showRemaining) {
        percentage = 0
        if (daysRemaining > 0) {
            percentage = (daysRemaining / TOTAL_TRIAL_DAYS) * 100
        }
    } else {
        if (daysRemaining > 0) {
            percentage = (1 - daysRemaining / TOTAL_TRIAL_DAYS) * 100
        }
    }

    if (circular)
        return (
            <CircularProgress
                value={percentage}
                color="progress.appBar.bar"
                trackColor="progress.appBar.track"
                size={size}
                thickness="6px"
            >
                <div
                    style={{
                        position: 'absolute',
                        textAlign: 'center',
                        width: size,
                        height: size,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1rem',
                        top: 0,
                    }}
                >
                    {children}
                </div>
            </CircularProgress>
        )

    return (
        <>
            <div>
                <Progress
                    value={percentage}
                    barColor="progress.admin"
                    mb={2}
                    borderRadius="3px"
                    bg="progress.trackColor"
                    h="6px"
                />
                <div style={{ textAlign: 'center', margin: '13px 0 14px' }}>
                    <span style={{ ...V4DesignSystem.settingLabel, marginRight: '5px' }}>
                        Free trial
                    </span>
                    <span style={{ ...V4DesignSystem.label }}>
                        {daysRemaining > 0 ? daysRemaining : 0} days left
                    </span>
                </div>
            </div>
            {showPlanButton && (
                <>
                    <Button
                        data-testid="trial-progress.choose-a-plan"
                        variant="adminPrimaryV4"
                        mb="6px"
                        href=""
                        w="100%"
                        onClick={() => {
                            analytics.track('clicked choose a plan', {})
                            openPlanPickerModal({ fromWorkspaceSettingsModal: true })
                        }}
                    >
                        Choose a plan
                    </Button>
                </>
            )}
            {hasRight(Rights.ContactSupport) && (
                <>
                    <Button
                        variant="adminSecondaryV4"
                        mb="6px"
                        href="https://stackerhq.com/call"
                        icon="calendar"
                        w="100%"
                    >
                        Book a 1:1
                    </Button>
                    <Button
                        variant="adminSecondaryV4"
                        w="100%"
                        onClick={() => {
                            onClose()
                            window.Pylon('showNewMessage')
                        }}
                        icon="comments"
                    >
                        Contact Us
                    </Button>
                </>
            )}
        </>
    )
}

export default TrialProgress
